/*------------------------------------------------
// File Name:PDM_BodyCheck_PaiBanTiem.js
// File Description:PDM_BodyCheck_PaiBanTiem API方法js接口
// Author:zxl
// Create Time:2023/05/11 15:10:03
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _bodycheckPaibantiem: "/PDM_BodyCheck_PaiBanTime"
}

export default api


//-------------------创建体检预约排班表-时间对象-------------------
export function CreatePDM_BodyCheck_PaiBanTimeEntity(parameter) {
    return axios({
        url: api._bodycheckPaibantiem + "/CreatePDM_BodyCheck_PaiBanTimeEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取体检预约排班表-时间列表-------------------
export function GetPDM_BodyCheck_PaiBanTimeList(parameter) {
    return axios({
        url: api._bodycheckPaibantiem + "/GetPDM_BodyCheck_PaiBanTimeList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取体检预约排班表-时间列表---------------
export function GetPDM_BodyCheck_PaiBanTimeListByPage(parameter) {
    return axios({
        url: api._bodycheckPaibantiem + "/GetPDM_BodyCheck_PaiBanTimeListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取体检预约排班表-时间对象---------------
export function GetPDM_BodyCheck_PaiBanTimeEntity(parameter) {
    return axios({
        url: api._bodycheckPaibantiem + "/GetPDM_BodyCheck_PaiBanTimeEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存体检预约排班表-时间对象---------------
export function UpdatePDM_BodyCheck_PaiBanTimeEntity(parameter) {
    return axios({
        url: api._bodycheckPaibantiem+ "/UpdatePDM_BodyCheck_PaiBanTimeEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉体检预约排班表-时间对象---------------
export function DeletePDM_BodyCheck_PaiBanTimeEntity(parameter) {
    return axios({
        url: api._bodycheckPaibantiem + "/DeletePDM_BodyCheck_PaiBanTimeEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------根据排班ID获取排班时间---------------
export function GetPaiBanTimeData(parameter) {
    return axios({
        url: api._bodycheckPaibantiem + "/GetPaiBanTimeData",
        method: 'post',
        data: parameter
    })
}

//-------------------根据排班ID修改排班时间---------------
export function UpdatePaiBanTimeData(parameter) {
    return axios({
        url: api._bodycheckPaibantiem + "/UpdatePaiBanTimeData",
        method: 'post',
        data: parameter
    })
}

//-------------------根据排班ID修改排班时间---------------
export function UpdatePaiBanTimeData2(parameter) {
    return axios({
        url: api._bodycheckPaibantiem + "/UpdatePaiBanTimeData2",
        method: 'post',
        data: parameter
    })
}

//-------------------根据排班ID删除排班时间---------------
export function DeletePDM_BodyCheck_PaiBanTime(parameter) {
    return axios({
        url: api._bodycheckPaibantiem + "/DeletePDM_BodyCheck_PaiBanTime",
        method: 'post',
        data: parameter
    })
}
